<template>
  <div class="create-offer-view">
    <offer-view-base-header
      :offer-recipient="customerOrganizationName"
      @go-back="goBack"
    />
    <template v-if="pageCms">
      <div class="create-offer-view__title-wrapper">
        <h1 class="create-offer-view__title">{{ pageTitle }}</h1>
        <button
          class="create-offer-view__new-tab-button"
          @click="handleOnNewTabClick"
        >
          <img :src="`/images/new_tab.svg`" alt="new-tab" />
          Hvordan lage tilbud?
        </button>
      </div>

      <div class="create-offer-view__body">
        <blank-offer-item :page-cms="pageCms" @new-offer="createNewOffer" />
        <div
          v-if="fetchedTenderDetails.length > 0"
          class="create-offer-view__previous-offers"
        >
          <reuse-previous-offers
            :page-cms="pageCms"
            :service-offers="serviceOffers"
            :all-tenders="fetchedTenderDetails"
            @new-offer="createNewReusableOffer"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import OfferViewBaseHeader from "@/components/OfferViewBaseHeader/OfferViewBaseHeader.vue";
import BlankOfferItem from "@/views/TenderOffer/CreateNewOffer/BlankOfferItem.vue";
import ReusePreviousOffers from "@/views/TenderOffer/CreateNewOffer/PreviousOffers.vue";
import cmsService from "@/services/cmsService";
import { mapGetters } from "vuex";
import { RequestType } from "@/custom-types/types";
import { RouteNames } from "@/router/routes";
import { useTenderApi } from "@/services/api/useTenderApi";

const { getTender } = useTenderApi();

export default {
  components: {
    ReusePreviousOffers,
    BlankOfferItem,
    OfferViewBaseHeader,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageCms: null,
      fetchedTenderDetails: [],
    };
  },
  computed: {
    ...mapGetters("offers", {
      allTenders: "getAllTenders",
    }),
    customerOrganizationName() {
      return this.tender?.customerOrganization?.name;
    },
    pageTitle() {
      return this.pageCms ? this.pageCms.title : "";
    },
    filteredTenders() {
      return this.allTenders
        ? this.allTenders.filter((currentTender) => {
            const currentPriceRequest = currentTender.priceRequests?.[0];
            const targetPriceRequest = this.tender.priceRequests?.[0];
            return (
              currentPriceRequest?.requestType ===
                RequestType.ServiceWithoutRobot &&
              currentPriceRequest?.serviceTypeLabel ===
                targetPriceRequest?.serviceTypeLabel &&
              currentPriceRequest?.serviceCategoryLabel ===
                targetPriceRequest?.serviceCategoryLabel
            );
          })
        : [];
    },
    serviceOffers() {
      return this.fetchedTenderDetails
        .flatMap((tender) => tender.supplierPortfolios)
        .flatMap((portfolio) => portfolio.serviceTypePackagePortfolio)
        .flatMap((packagePortfolio) => packagePortfolio.serviceOffers);
    },
  },
  created() {
    this.fetchPageCms();
    this.fetchFilteredTenderDetails();
  },
  methods: {
    async fetchPageCms() {
      const response = await cmsService.getCreateOfferPage();
      this.pageCms = response[0];
    },
    async fetchTenderDetails(tenderId) {
      try {
        const response = await getTender({
          supplierId: this.supplierInfo.id,
          tenderId: tenderId,
        });
        return response.data;
      } catch (error) {
        console.error(
          `Error fetching tender details for tenderId ${tenderId}:`,
          error,
        );
        return null;
      }
    },
    async fetchFilteredTenderDetails() {
      const fetchPromises = this.filteredTenders.map((tender) =>
        this.fetchTenderDetails(tender.tenderId),
      );
      const tenderDetails = await Promise.all(fetchPromises);
      this.fetchedTenderDetails = tenderDetails.filter(
        (detail) => detail !== null,
      );
    },
    createNewReusableOffer({ serviceOffer, tenderId }) {
      const query = serviceOffer
        ? { "reusable-offer": serviceOffer.id, "reusable-tender": tenderId }
        : {};

      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        query,
      });
    },
    createNewOffer(reusableServiceOffer = null) {
      const query = {};
      if (reusableServiceOffer) {
        query["reusable-offer"] = reusableServiceOffer.id;
      }
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        query: query,
      });
    },
    goBack() {
      this.$router.back();
    },
    handleOnNewTabClick() {
      window.open(import.meta.env.VITE_APP_NEED_HELP_OFFER_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.create-offer-view {
  padding: 4.75rem 0;

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-width: 37.125rem;
    margin: 3rem auto 0;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__new-tab-button {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--Yellow-Dark, #ecba4e);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--Black, #1d1d1d);
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: rgba(236, 186, 78, 0.8);
    }

    &:active {
      background: rgba(236, 186, 78, 0.6);
    }
  }

  &__body {
    max-width: 37.125rem;
    margin: 3rem auto 0;
  }

  &__previous-offers {
    margin-top: 2.75rem;
  }
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
</style>
