import dayjs from "dayjs";
import "dayjs/locale/nb";

type DateParam = Date | string | undefined | null;

export const formatToDateTimeString = (date: DateParam): string => {
  return `${formatToDateString(date)} ${formatDateInNorwegian(date, "HH:mm")}`;
};

export const formatToLongDateString = (date: DateParam): string => {
  return formatToDateString(date, "long");
};

export const formatToDateString = (
  date: DateParam,
  monthStyle: "number" | "short" | "long" = "number",
): string => {
  if (monthStyle === "number") {
    return formatDateInNorwegian(date, "DD.MM.YYYY");
  }
  if (monthStyle === "short") {
    return formatDateInNorwegian(date, "DD. MMM YYYY");
  }
  return formatDateInNorwegian(date, "DD. MMMM YYYY");
};

export const formatDateInNorwegian = (
  date: DateParam,
  format: string,
): string => {
  return date ? dayjs(date).locale("nb").format(format) : "";
};

export const extractDateString = (dateTimeStr: string) =>
  dateTimeStr.split("T")[0];

export const formatDateBasedOnTimeLapsed = (date: DateParam) => {
  if (!date) {
    return "";
  }
  const diffInDays = dayjs()
    .startOf("day")
    .diff(dayjs(date).startOf("day"), "day");
  const timeFormat = "kl. HH:mm";
  if (diffInDays === 0) {
    return `i dag ${formatDateInNorwegian(date, timeFormat)}`;
  }
  if (diffInDays === 1) {
    return `i går ${formatDateInNorwegian(date, timeFormat)}`;
  }
  if (diffInDays >= 2 && diffInDays <= 6) {
    return formatDateInNorwegian(date, `dddd ${timeFormat}`);
  }
  return formatDateInNorwegian(date, "DD.MMM YYYY");
};
