import { defineStore } from "pinia";
import {
  Supplier,
  ServiceCategory,
  SustainabilityGoal,
  Certification,
  SupplierAddress,
  ServiceType,
} from "./types";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useSystemApi } from "@/services/api/useSystemApi";

export const useSupplierStore = defineStore("supplier", {
  state: () => ({
    supplier: null as Supplier | null,
    serviceTypes: [] as ServiceType[],
    serviceCategories: [] as ServiceCategory[],
    sustainabilityGoals: [] as SustainabilityGoal[],
    certifications: [] as Certification[],
    supplierAddresses: [] as SupplierAddress[],
    loading: false,
    error: null as string | null,
  }),
  actions: {
    async fetchSupplierByUserId(userId: string) {
      this.loading = true;
      this.error = null;

      try {
        const { getSupplierByUserId } = useSupplierApi();
        const response = await getSupplierByUserId(userId);
        this.supplier = response.data;
      } catch (error) {
        this.error = error instanceof Error ? error.message : "Unknown error";
      } finally {
        this.loading = false;
      }
    },
    async fetchServiceTypes() {
      this.loading = true;
      this.error = null;

      try {
        const { getServiceTypes } = useSystemApi();
        const response = await getServiceTypes();
        this.serviceTypes = response.data;
      } catch (error) {
        this.error = error instanceof Error ? error.message : "Unknown error";
      } finally {
        this.loading = false;
      }
    },
  },
});
