<template>
  <div class="previous-offers">
    <div class="previous-offers__title">{{ title }}</div>
    <div
      v-if="reusableServiceOffers.length"
      class="previous-offers__offers-list"
    >
      <div
        v-for="offer in visibleOffers"
        :key="offer.id"
        class="previous-offers__offer-item"
      >
        <base-new-offer-item
          :title="offer.title"
          :subtitle="offer.subtitle"
          :right-text="offer.amount"
          :left-icon-src="leftIconSrc"
          :right-icon-src="rightIconSrc"
          @clicked="onOfferItemClick(offer.serviceOffer, offer.tenderId)"
        />
      </div>
      <button
        v-if="showSeeMoreOffersButton"
        class="previous-offers__see-more-button"
        @click="seeMoreOffers"
      >
        {{ seeMoreText }}
      </button>
    </div>
    <div v-else class="previous-offers__no-offers">
      <no-previous-offers />
    </div>
  </div>
</template>

<script>
import NoPreviousOffers from "@/views/TenderOffer/CreateNewOffer/NoPreviousOffers.vue";
import BaseNewOfferItem from "@/views/TenderOffer/CreateNewOffer/BaseNewOfferItem.vue";
import Mustache from "mustache";
import { getIconUrl, getOtherText } from "@/utilities/cmsUtilities";
import { BillingCycleUnits } from "@/custom-types/types";
import { extractFixedActivitiesFromManualServiceOffer } from "@/utilities/tenderUtilities";
import { formatCurrency } from "@/utilities/numberUtils";

export default {
  components: { BaseNewOfferItem, NoPreviousOffers },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    serviceOffers: {
      type: Array,
      required: true,
    },
    allTenders: {
      type: Array,
      required: true,
    },
  },
  emits: ["new-offer"],
  data() {
    return {
      collapsedOffersCount: 4,
      showAllOffers: false,
    };
  },
  computed: {
    title() {
      return getOtherText(this.pageCms.otherText, "previousOffersTitle");
    },
    serviceTypesCms() {
      return this.$store.getters["cms/getServiceTypes"];
    },
    reusableServiceOffers() {
      const reusableOffers = [];
      this.serviceOffers.forEach((serviceOffer) => {
        const matchingTender = this.allTenders.find((tender) => {
          return tender.supplierPortfolios
            .flatMap(
              (supplierPortfolio) =>
                supplierPortfolio.serviceTypePackagePortfolio,
            )
            .flatMap(
              (serviceTypePackagePortfolio) =>
                serviceTypePackagePortfolio.serviceOffers,
            )
            .find((currentServiceOffer) => {
              return currentServiceOffer.id === serviceOffer.id;
            });
        });
        const fixedCostActivities =
          extractFixedActivitiesFromManualServiceOffer(
            serviceOffer,
            matchingTender,
          );
        const variableCostActivities =
          extractFixedActivitiesFromManualServiceOffer(
            serviceOffer,
            matchingTender,
          );
        if (!fixedCostActivities.length && !variableCostActivities.length) {
          return;
        }
        const tenderId = matchingTender?.tenderId;
        const priceRequest = matchingTender?.priceRequests[0];
        const serviceTypeLabel = priceRequest?.serviceTypeLabel;
        const serviceTypeCms = this.serviceTypesCms?.find(
          (serviceTypeCms) => serviceTypeCms.label === serviceTypeLabel,
        );
        const serviceType = serviceTypeCms?.name || serviceTypeLabel || "";
        const customerOrgName = matchingTender?.customerOrganization.name || "";
        const propertyAddress = serviceOffer?.supplierStreetAddress || "";
        const nickname = serviceOffer.serviceOfferExtra?.nickname;
        reusableOffers.push({
          id: serviceOffer.id,
          tenderId: tenderId,
          title: nickname ? nickname : "Tilbud på tjenestetype",
          subtitle: [serviceType, customerOrgName, propertyAddress].join(" - "),
          amount: this.getFormattedServiceOfferPrice(serviceOffer),
          serviceOffer: serviceOffer,
        });
      });
      return reusableOffers;
    },
    visibleOffers() {
      return this.showAllOffers
        ? this.reusableServiceOffers
        : this.reusableServiceOffers.slice(0, this.collapsedOffersCount);
    },
    showSeeMoreOffersButton() {
      return (
        this.reusableServiceOffers.length > this.collapsedOffersCount &&
        !this.showAllOffers
      );
    },
    leftIconSrc() {
      return getIconUrl(this.pageCms.icons, "reuseOfferIcon");
    },
    rightIconSrc() {
      return getIconUrl(this.pageCms.icons, "arrowRightIcon");
    },
    seeMoreText() {
      const seeMoreText = getOtherText(
        this.pageCms.otherText,
        "seeMoreOffersText",
      );
      return Mustache.render(seeMoreText, {
        count: this.visibleOffers.length,
        total: this.reusableServiceOffers.length,
      });
    },
  },
  methods: {
    getFormattedServiceOfferPrice(serviceOffer) {
      const billingCycle = BillingCycleUnits[serviceOffer.billingCycle];
      return `${formatCurrency(serviceOffer.supplierCost)} ${
        billingCycle ?? ""
      }`;
    },
    seeMoreOffers() {
      this.showAllOffers = true;
    },
    onOfferItemClick(serviceOffer, tenderId) {
      this.$emit("new-offer", {
        serviceOffer: serviceOffer,
        tenderId: tenderId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.previous-offers {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
    color: $color-black;
  }

  &__no-offers {
    margin-top: 1rem;
  }

  &__offers-list {
    margin-top: 0.625rem;
  }

  &__offer-item {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__see-more-button {
    margin-top: 1rem;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: $font-size-sm;
    text-decoration: underline;
  }
}
</style>
