<template>
  <div class="messages-view">
    <price-request-conversation
      v-if="isTenderPendingAcceptance"
      :tender="tender"
      :supplier-id="supplierId"
      :price-request="priceRequest"
    />
    <tender-conversations
      v-else
      :tender="tender"
      :supplier-id="supplierId"
      :price-request="priceRequest"
    />
  </div>
</template>

<script>
import PriceRequestConversation from "@/components/Conversation/PriceRequestConversation.vue";
import { TenderState } from "@/custom-types/types";
import TenderConversations from "@/components/Conversation/TenderConversations.vue";

export default {
  name: "MessagesWrapper",
  components: { TenderConversations, PriceRequestConversation },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isTenderPendingAcceptance() {
      return (
        this.tender.tenderState === TenderState.Offered ||
        this.tender.tenderState === TenderState.PriceRequested
      );
    },
    priceRequest() {
      return this.tender ? this.tender.priceRequests[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-view {
  min-height: 28rem;
  background-color: #fff;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: $color-black;
}
</style>
@/types/AppTypes
