// The current application coordinates were pre-registered in a B2C tenant.
const BASE_URI = import.meta.env.VITE_APP_BASE_URI;
const SUPPLIER_BASE_URI = import.meta.env.VITE_APP_SUPPLIER_BASE_URI;
const USER_BASE_URI = import.meta.env.VITE_APP_USER_BASE_URI;
const COMPANY_URI = import.meta.env.VITE_APP_COMPANY_BASE_URI;
const TENDER_BASE_URI = import.meta.env.VITE_APP_TENDER_BASE_URI;

export default {
  apiConfig: {
    b2cScopes: [
      "https://reallyservicesb2c.onmicrosoft.com/955e9d9d-8123-4b35-b585-e35fd4c73ef2/Read",
    ],

    // User
    registerApi: `${USER_BASE_URI}/user/Create`,
    getUserApi: `${USER_BASE_URI}/user/users/`,
    updateUserApi: `${USER_BASE_URI}/Edit`,
    getSupplierUsers: `${USER_BASE_URI}/users/supplier`,
    findCompanyApi: `${COMPANY_URI}/FindCompany/`,
    getUserByEmailApi: `${USER_BASE_URI}/Users/Email/`,

    // Supplier Details
    getSupplierProfile:
      "https://apim-reallyservices.azure-api.net/suppliers/suppliers/supplierById/",
    getCityApi: `${BASE_URI}/Address/FindCity/`,

    getSupplierInfoApi: `${SUPPLIER_BASE_URI}/user/`,
    getSupplierDetailsApi: `${SUPPLIER_BASE_URI}/`,

    getSupplierReservationsApi: `${SUPPLIER_BASE_URI}/supplier/`,
    saveSupplierReservationsApi: `${SUPPLIER_BASE_URI}/supplier/`,

    getSupplierTypesBySupplierApi: `${SUPPLIER_BASE_URI}/`,

    // Tender
    getTenderOffersBySupplier: `${TENDER_BASE_URI}/Supplier/`,
    getTenderFormBySupplierType: `${TENDER_BASE_URI}/TenderForm/`,
    completeTenderOffer: `${TENDER_BASE_URI}/`,
    sendInformationRequestMessage: `${TENDER_BASE_URI}/`,
    getTenderConfirmationApi: `${TENDER_BASE_URI}/`,
    sendRejectPriceRequestApi: `${TENDER_BASE_URI}/`,
    getSupplierOffersApi: `${TENDER_BASE_URI}/`,
    getTenderOffer: `${TENDER_BASE_URI}`,
    getTender: `${TENDER_BASE_URI}`,
    sendOffer: `${TENDER_BASE_URI}`,
    deleteSupplierOffer: `${TENDER_BASE_URI}`,
    withdrawSupplierOffer: `${TENDER_BASE_URI}`,
    setAgreementResponsible: `${TENDER_BASE_URI}`,
    getInvoiceRecipient: `${TENDER_BASE_URI}/InvoiceRecipient/ExternalTenderId/`,
    submitTender: `${TENDER_BASE_URI}/Create`,

    // Supplier Segments
    getSupplierTypesApi: `${BASE_URI}/SupplierSegments/SupplierTypes`,
    getPropertyTypesApi: `${BASE_URI}/SupplierSegments/PropertyTypes`,
    getSupplierSegmentsApi: `${BASE_URI}/SupplierSegments/SupplierType/`,
    getSupplierRangesApi: `${BASE_URI}/SupplierSegments/Segment/`,
    getPricingPackagesApi: `${BASE_URI}/SupplierSegments/SupplierType/`,
    getSupplierPricesApi: `${BASE_URI}/SupplierSegments/PropertyType/`,

    getSupplierPriceGroupsApi: `${BASE_URI}/SupplierSegments/PropertyType/`,
    getFixedSupplierPriceGroupsApi: `${BASE_URI}/SupplierSegments/PropertyType/`,

    saveRangesApi: `${BASE_URI}/SupplierSegments/SaveRanges`,
    savePricingApi: `${BASE_URI}/SupplierSegments/SavePrices`,

    newSupplierApi: `${BASE_URI}/supplier/supplierType`,

    // Supplier Communication
    sendMessageApi: `${BASE_URI}/SupplierCommunications/Create`,
    getSupplierConversationsApi: `${BASE_URI}/SupplierCommunications/request/`,
    getConversationMessagesApi: `${BASE_URI}/SupplierCommunications/conversation/`,
    getAttachmentsApi: `${BASE_URI}/SupplierCommunications/Messages/`,
    getSupplierUnreadMessagesCountApi: `${BASE_URI}/SupplierCommunications/UnreadMessages/`,
    getAllSupplierUnreadMessagesCountApi: `${BASE_URI}/SupplierCommunications/UnreadMessages/`,
    markMessagesReadApi: `${BASE_URI}/SupplierCommunications/read`,
    getMessageAttachmentFilesApi: `${BASE_URI}/SupplierCommunications/conversation/`,

    // File Attachment
    getFileAttachmentApi: `${BASE_URI}/attachment/`,

    // Service Report
    getServiceReportApi: `${BASE_URI}/ServiceReport/Report`,
    createServiceReportApi: `${BASE_URI}/ServiceReport/Report`,
    deleteServiceReport: `${BASE_URI}/ServiceReport/Report`,

    // Address
    createSupplierAddressApi: `${SUPPLIER_BASE_URI}/Address`,
    updateSupplierAddressApi: `${SUPPLIER_BASE_URI}/`,
    getSupplierAddressesApi: `${SUPPLIER_BASE_URI}/`,
    deleteSupplierAddressApi: `${SUPPLIER_BASE_URI}/`,

    //System
    createSupplierTenderOffer: `${BASE_URI}/system/offer`,
    updateSupplierTenderOffer: `${BASE_URI}/system/offer`,
    getActivityFormFrequencyOptions: `${BASE_URI}/system/segmentOptions`,
    getServiceCategories: `${BASE_URI}/system/serviceCategories`,
  },
};
