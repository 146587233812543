<template>
  <div class="service-documentation">
    <b-container>
      <b-row>
        <b-col cols="12" md="8">
          <upload-documentation
            :tender="tender"
            :service-offer-id="serviceOfferId"
            @new-service-report="handleNewServiceReport"
          />
        </b-col>
      </b-row>
      <div class="service-documentation__reports">
        <div class="service-documentation__reports-title">
          <span>{{ serviceReportContent.myServiceReport.title }}</span>
          <span class="service-documentation__reports-title-subtext">
            {{ serviceReportContent.myServiceReport.subtitle }}
          </span>
        </div>
        <div class="service-documentation__reports-list-wrapper">
          <service-reports-list
            :service-offer-id="serviceOfferId"
            :tender-id="tender.tenderId"
            :customer-org-number="tender.customerOrganization.orgNumber"
            :service-reports="serviceReports"
            @delete-service-report="deleteServiceReport"
          />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import UploadDocumentation from "./UploadDocumentation";
import ServiceReportsList from "@/components/ServiceReport/ServiceReportsList.vue";
import { mapGetters } from "vuex";
import serviceReportsService from "@/services/serviceReportsService";

export default {
  components: { ServiceReportsList, UploadDocumentation },
  props: {
    tender: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serviceReports: null,
    };
  },
  computed: {
    ...mapGetters("cms", {
      serviceReportContent: "getServiceReportContent",
    }),
    serviceOfferId() {
      if (!this.tender) return;
      const serviceTypePackagePortfolio =
        this.findSelectedServiceTypePackagePortfolioTender(this.tender);
      if (!serviceTypePackagePortfolio) return;
      const serviceOfferId = serviceTypePackagePortfolio.serviceOffers[0].id;
      if (!serviceOfferId) return;
      return serviceOfferId;
    },
  },
  created() {
    this.$store.dispatch("cms/loadServiceReportContent");
    this.fetchServiceReports();
  },
  methods: {
    async fetchServiceReports() {
      this.serviceReports = null;
      const response = await serviceReportsService.fetchServiceReports({
        customerOrgNumber: this.tender.customerOrganization.orgNumber,
        tenderId: this.tender.tenderId,
        serviceOfferId: this.serviceOfferId,
      });
      this.serviceReports = response.data ? response.data : [];
    },
    findSelectedServiceTypePackagePortfolioTender(tender) {
      const supplierPortfolios = tender.supplierPortfolios;
      if (!supplierPortfolios) {
        return;
      }
      const serviceTypePackagePortfolios = supplierPortfolios.flatMap(
        (supplierPortfolio) => supplierPortfolio.serviceTypePackagePortfolio,
      );
      return serviceTypePackagePortfolios.find((serviceTypePackagePortfolio) =>
        this.isServiceTypePackagePortfolioSelected(serviceTypePackagePortfolio),
      );
    },
    isServiceTypePackagePortfolioSelected(serviceTypePackagePortfolio) {
      return serviceTypePackagePortfolio.serviceTypePortfolioAcceptance;
    },
    handleNewServiceReport() {
      this.fetchServiceReports();
    },
    deleteServiceReport(serviceReportId) {
      for (let i = this.serviceReports?.length - 1; i >= 0; i--) {
        if (this.serviceReports?.[i].id === serviceReportId) {
          this.serviceReports?.splice(i, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.service-documentation {
  padding: 3rem 2rem;
  background-color: #fff;
  text-align: start;

  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 1400px;
  }

  &__reports-title {
    font-weight: 700;
    font-size: $font-size-base;
    color: $color-primary-darker;
    border-bottom: 0.125rem solid #aea993;
  }

  &__reports-title-subtext {
    color: $color-primary-darker;
    font-weight: 400;
    font-size: $font-size-sm;
    margin-left: 0.5rem;
  }
}
</style>
