<template>
  <div class="tender-conversations">
    <template v-if="isReady">
      <conversation-thread
        :conversation="conversation"
        :tender="tender"
        @send-message="sendMessage"
      />
    </template>
    <div v-else class="tender-conversations__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script>
import ConversationThread from "@/components/Conversation/ConversationThread.vue";
import supplierCommunicationService from "@/services/supplierCommunicationService";
import { MessageDomain, MessageType, RequestState } from "@/custom-types/types";
import { getMessageAttachmentDto } from "@/utilities/fileUtilities";
import tenderService from "@/services/tenderService";

export default {
  components: { ConversationThread },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    priceRequest: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isReady: false,
      conversation: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.api.user;
    },
    supplierRequest() {
      return this.priceRequest.supplierRequests.find(
        (supplierRequest) => supplierRequest.supplierId === this.supplierId,
      );
    },
  },
  async created() {
    await this.fetchPriceRequestConversation();
    this.isReady = true;
  },
  methods: {
    async fetchConversations() {
      const supplierRequestId = this.supplierRequest?.id;
      if (!supplierRequestId) {
        console.error("Supplier request ID is undefined");
        return;
      }

      try {
        const response =
          await supplierCommunicationService.fetchSupplierConversations(
            supplierRequestId,
          );
        return response.data;
      } catch (error) {
        console.error("Failed to fetch conversations:", error);
      }
    },
    async fetchConversationMessages(conversationId) {
      const response =
        await supplierCommunicationService.fetchConversationMessages(
          conversationId,
        );
      return response.data;
    },
    async fetchPriceRequestConversation(mustHaveConversation = false) {
      if (!this.conversation) {
        let conversations = await this.fetchConversations();
        if (mustHaveConversation) {
          while (!conversations.length) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            conversations = await this.fetchConversations();
          }
        }
        const sortedConversations = conversations
          .slice()
          .sort((conversationA, conversationB) => {
            const latestDateA = new Date(conversationA.message.createdOn);
            const latestDateB = new Date(conversationB.message.createdOn);
            return latestDateA.getTime() - latestDateB.getTime();
          });
        this.conversation = sortedConversations.length
          ? sortedConversations[0]
          : null;
      }
      if (this.conversation) {
        const newConversation = await this.fetchConversationMessages(
          this.conversation.latestMessage.conversationId,
        );
        Object.assign(this.conversation, newConversation);
      }
    },
    async sendMessage(payload) {
      const message = payload.message;
      const messageAttachments = await Promise.all(
        payload.attachments.map((file) => getMessageAttachmentDto(file)),
      );
      if (
        !this.conversation &&
        (this.supplierRequest?.requestState === RequestState.PriceRequested ||
          RequestState.InformationRequested)
      ) {
        await this.sendNeedMoreInfoMessage(message, messageAttachments);
      } else {
        await this.sendGeneralMessage(message, messageAttachments);
      }
      await this.fetchPriceRequestConversation(true);
      payload.successCallback();
      payload.errorCallback();
    },
    async sendNeedMoreInfoMessage(message, messageAttachments) {
      const messageDto = {
        customerPropertyId: this.priceRequest.customerPropertyId,
        supplierId: this.supplierRequest?.supplierId,
        message: message,
        attachments: messageAttachments,
        tenderId: this.tender.tenderId,
        userId: this.$store.state.api.user.userId,
      };
      const response = await tenderService.sendInformationRequestMessage(
        this.tender.tenderId,
        messageDto,
      );
      return response;
    },
    async sendGeneralMessage(message, messageAttachments) {
      const messageDto = {
        conversationId: this.conversation
          ? this.conversation.latestMessage.conversationId
          : null,
        messageType: MessageType.GeneralMessage,
        messageBody: message,
        attachments: messageAttachments,
        senderUserId: this.user.userId,
        senderName: this.user.name,
        senderEmail: this.user.email,
        supplierId: this.supplierRequest ? this.supplierRequest.supplierId : "",
        supplierRequestId: this.supplierRequest ? this.supplierRequest.id : "",
        tenderId: this.tender.tenderId,
        rootMessage: !this.conversation,
        messageDomain: MessageDomain.SupplierCustomer,
        price: null,
      };
      const response =
        await supplierCommunicationService.sendMessage(messageDto);
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-conversations {
  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
@/utilities/fileUtilities @/types/AppTypes
