import { defineStore } from "pinia";
import { ContentType } from "@/custom-types/CmsContentTypes";
import {
  Header,
  Pages,
  ServiceType,
  Certifications,
  SustainabilityGoals,
  PriceGroup,
} from "./types";

type CmsState = {
  [ContentType.HEADER_CMS]: Header | null;
  [ContentType.HEADER_MENU_CMS]: Header | null;
  [ContentType.SERVICE_TYPES_CMS]: ServiceType[] | null;
  [ContentType.SUPPLIER_TASKS_PAGE_CMS]: Pages | null;
  [ContentType.SUPPLIER_PROFILE_PAGE_CMS]: Pages | null;
  [ContentType.SUPPLIER_PRICE_ROBOT_PAGE_CMS]: Pages | null;
  [ContentType.SUPPLIER_REGISTRATIONS_PAGE_CMS]: Pages | null;
  [ContentType.SUPPLIER_CERTIFICATIONS_CMS]: Certifications[] | null;
  [ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS]: SustainabilityGoals[] | null;
  [ContentType.PRICE_GROUPS_CMS]: PriceGroup[] | null;
  [ContentType.SUPPLIER_WINDOW_CONSTRAINT_PAGE_CMS]: Pages | null;
  [ContentType.SUPPLIER_LOADING_PAGE_CMS]: Pages | null;
  [ContentType.SERVICE_ACTIVITIES_CMS]: Pages | null;
  [ContentType.EDIT_SUPPLIER_OFFER_PAGE_CMS]: Pages | null;
  [ContentType.CREATE_OFFER_PAGE_CMS]: Pages | null;
  [ContentType.TERMS_AND_CONDITIONS_PAGE_CMS]: Pages | null;
  [ContentType.SEND_OFFER_MODAL_PAGE_CMS]: Pages | null;
  [ContentType.GIVE_OFFER_PAGE_CMS]: Pages | null;
};

export const useCmsStore = defineStore("cms", {
  state: (): CmsState => {
    const initialState: CmsState = {} as CmsState;
    Object.values(ContentType).forEach((contentType) => {
      initialState[contentType] = null;
    });
    return initialState;
  },
  actions: {
    setContent<T extends ContentType>(contentType: T, content: CmsState[T]) {
      (this as CmsState)[contentType] = content;
    },
  },
});
