<template>
  <div class="price-request-details">
    <div class="price-request-details__grid">
      <div class="price-request-details__item">
        <h3>Referansekode</h3>
        <div class="price-request-details__divider" />
        <p>{{ referenceCode }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Tjenestekategori</h3>
        <div class="price-request-details__divider" />
        <p>{{ serviceCategory }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Forespørsel sendt</h3>
        <div class="price-request-details__divider" />
        <p>{{ requestSent }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Ønsket oppstartsdato</h3>
        <div class="price-request-details__divider" />
        <p>{{ desiredStartDate }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Felles befaring</h3>
        <div class="price-request-details__divider" />
        <p v-if="jointInspection">{{ jointInspection }}</p>
      </div>
      <div class="price-request-details__item">
        <h3>Vilkår</h3>
        <div class="price-request-details__divider" />
        <template v-if="!isLoadingFrameAgreement">
          <attachment
            v-for="attachment in displayedAttachments"
            :key="attachment.fileName"
            :file-name="attachment.fileName"
            :download-handler="attachment.downloadHandler"
            class="price-request-details__attachment"
          />
          <p v-if="displayedAttachments.length === 0">Ingen vedlegg</p>
        </template>
        <div v-else class="price-request-details__spinner-wrapper">
          <b-spinner type="grow" class="price-request-details__loader" />
        </div>
      </div>
    </div>

    <div class="price-request-details__full-width">
      <h3>Fellesmeldinger</h3>
      <div class="price-request-details__divider" />
      <PublicNotes :tender="tender" :price-request="priceRequest" />
    </div>

    <div class="price-request-details__full-width">
      <h3>
        Arbeidsbeskrivelse
        <button
          class="price-request-details__toggle-btn"
          @click="toggleWorkDescription"
        >
          <img
            :src="isWorkDescriptionTruncated ? ArrowDownIcon : ArrowUpIcon"
          />
          {{ isWorkDescriptionTruncated ? "Vis mer" : "Vis mindre" }}
        </button>
      </h3>
      <div class="price-request-details__divider" />
      <p v-if="truncatedWorkDescription">{{ truncatedWorkDescription }}</p>
      <p v-else>Ingen beskrivelse av arbeid</p>
    </div>

    <div class="price-request-details__full-width">
      <h3>Vedlegg</h3>
      <div class="price-request-details__divider" />
      <div
        v-if="priceRequestAttachments.length"
        class="price-request-details__attachments"
      >
        <attachment
          v-for="(attachment, index) in priceRequestAttachments"
          :key="index"
          :download-handler="getAttachmentDownloadHandler(attachment)"
          :file-name="attachment.fileName"
          class="price-request-details__attachment"
        />
      </div>
      <div v-else class="price-request-details__no-attachment">
        <p>Ingen vedlegg</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import ArrowDownIcon from "@/assets/img/ArrowDown.svg";
import ArrowUpIcon from "@/assets/img/ArrowUp.svg";
import Attachment from "@/components/Attachment/Attachment.vue";
import useFileDownload from "@/hooks/useFileDownload";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import {
  FrameAgreement,
  Tender,
  MessageAttachmentDto,
} from "@/stores/tender/types";
import { LocalAttachment, TenderState } from "@/custom-types/GeneralTypes";
import PublicNotes from "@/components/PublicNotes/PublicNotes.vue";

const router = useRouter();
const {
  viewAttachmentFile,
  viewFileInNewWindow,
  viewOrderConfirmationDocument,
} = useFileDownload();
const { getAttachment } = useFileAttachmentApi();
const { getFrameAgreement } = useTenderApi();

enum AgreementAndTermsTitles {
  Agreement = "Avtale",
  Terms = "Avtalevilkår",
}

const agreementAndTermsCms = [
  {
    title: AgreementAndTermsTitles.Terms,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
  {
    title: AgreementAndTermsTitles.Agreement,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
];

interface Props {
  tender: Tender;
  supplierId: number;
  referenceCode: string;
  serviceCategory: string;
  requestSent: string;
  desiredStartDate: string;
  jointInspection?: string;
  sharedMessage: string;
  serviceOfferId: number;
}

const props = withDefaults(defineProps<Props>(), {
  jointInspection: undefined,
});

const frameAgreement = ref<FrameAgreement | null>(null);
const isWorkDescriptionTruncated = ref(true);
const isLoadingFrameAgreement = ref(true);
const maxSharedMessageLengthShort = 100;

const truncatedWorkDescription = computed(() => {
  if (!props.sharedMessage) return "";
  if (props.sharedMessage.length <= maxSharedMessageLengthShort) {
    return props.sharedMessage;
  }
  return isWorkDescriptionTruncated.value
    ? props.sharedMessage.slice(0, maxSharedMessageLengthShort) + "..."
    : props.sharedMessage;
});

const priceRequest = computed(() => props.tender.priceRequests?.[0]);

const priceRequestAttachments = computed(
  () => priceRequest.value?.messageAttachmentDtos || [],
);

const supplierRequest = computed(
  () => priceRequest.value?.supplierRequests?.[0],
);

const customerOrganizationId = computed(
  () => props.tender?.customerOrganizationId,
);

const hasFrameAgreement = computed(
  () => supplierRequest.value?.hasFrameAgreement ?? false,
);

const frameAgreementId = computed(
  () => supplierRequest.value?.frameAgreementId || null,
);

const frameAgreementAttachments = computed((): LocalAttachment[] => {
  if (isLoadingFrameAgreement.value) {
    return [];
  }
  if (!frameAgreement.value) {
    return [];
  }
  return frameAgreement.value.attachments.map(
    (attachment): LocalAttachment => ({
      fileName: attachment.fileName,
      attachmentUri: attachment.attachmentUri || "",
      downloadHandler: async () => {
        try {
          const response = await getAttachment({
            attachmentId: attachment.attachmentId,
            tenderId: props.tender.tenderId,
            fileName: attachment.fileName,
          });
          await viewFileInNewWindow(response.data, router);
        } catch (error) {
          console.error("Error downloading attachment:", error);
        }
      },
    }),
  );
});

const agreementAndTermsAttachments = computed((): LocalAttachment[] => {
  const documents = agreementAndTermsCms.filter(
    (document) =>
      document.title !== AgreementAndTermsTitles.Terms ||
      props.tender.tenderState === TenderState.ACCEPTED ||
      props.tender.tenderState === TenderState.REJECTED ||
      props.tender.tenderState === TenderState.COMPLETED,
  );

  return documents.map((document) => {
    const isAgreementDocument =
      document.title === AgreementAndTermsTitles.Terms;
    return {
      fileName: document.title,
      attachmentUri: isAgreementDocument ? "" : document.link,
      downloadHandler: isAgreementDocument
        ? async () => {
            try {
              await viewOrderConfirmationDocument(
                props.tender?.tenderId,
                props.serviceOfferId,
                router,
              );
            } catch (error) {
              console.error("Error viewing order confirmation:", error);
            }
          }
        : async () => {
            if (document.link) {
              window.open(document.link, "_blank");
            }
          },
    };
  });
});

const displayedAttachments = computed(() =>
  hasFrameAgreement.value
    ? frameAgreementAttachments.value
    : agreementAndTermsAttachments.value,
);

const fetchFrameAgreement = async () => {
  if (
    hasFrameAgreement.value &&
    frameAgreementId.value &&
    frameAgreementId.value > 0 &&
    customerOrganizationId.value
  ) {
    isLoadingFrameAgreement.value = true;
    try {
      const response = await getFrameAgreement({
        organizationId: customerOrganizationId.value,
        frameAgreementId: frameAgreementId.value,
      });
      frameAgreement.value = response.data || null;
    } catch (err) {
      console.error("Error fetching frame agreement:", err);
      frameAgreement.value = null;
    } finally {
      isLoadingFrameAgreement.value = false;
    }
  } else {
    isLoadingFrameAgreement.value = false;
  }
};

const toggleWorkDescription = () => {
  isWorkDescriptionTruncated.value = !isWorkDescriptionTruncated.value;
};

const getAttachmentDownloadHandler = (attachment: MessageAttachmentDto) => {
  const supplierRequestId = priceRequest.value?.supplierRequests?.[0]?.id;
  if (!supplierRequestId) return;
  return async () => {
    try {
      await viewAttachmentFile(
        {
          attachmentId: attachment.attachmentId,
          tenderId: props.tender.tenderId,
          supplierRequestId: supplierRequestId,
        },
        router,
      );
    } catch (error) {
      console.error("Error viewing attachment:", error);
    }
  };
};

onMounted(fetchFrameAgreement);
</script>

<style lang="scss" scoped>
.price-request-details {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 40px;
    margin-bottom: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__full-width {
    margin-bottom: 40px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__divider {
    height: 1px;
    background: #dfcfe5;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
  }

  &__vilkar,
  &__attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    background: white;
    border: 1px solid #5a5a5a;
    border-radius: 8px;
    width: fit-content;
  }

  &__download-btn {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__attachments {
    display: flex;
    gap: 4px;
  }

  &__toggle-btn {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;

    img {
      width: 24px;
      height: 24px;
      margin-left: 4px;
    }
  }

  .icon-download {
    display: inline-block;
    width: 20px;
    height: 17px;
    background-color: #1d1d1d;
  }
}

@media (max-width: 768px) {
  .price-request-details {
    padding: 24px;

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
