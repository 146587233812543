<template>
  <div>
    <div
      v-for="(listSection, index) in tendersList"
      :key="index"
      class="agreements-list__section"
    >
      <div v-if="listSection.title" class="agreements-list__title">
        {{ listSection.title }}
      </div>
      <template v-if="!loading">
        <base-list v-if="listSection.tenders.length">
          <supplier-tender-list-item
            v-for="tender in listSection.tenders"
            :key="tender.priceRequests[0].id"
            :service-types-cms="serviceTypesCms"
            :tender="tender"
            :price-request-id="tender.priceRequests[0].id"
            :collapsed="isTenderCollapsed(tender)"
            :list-item-id="'row-' + tender.priceRequests[0].id"
            :supplier-users="supplierUsers"
            @clicked="toggleShowTenderDetails(tender)"
          >
            <template #collapseContent>
              <tenders-list-detail-content
                v-if="!isTenderCollapsed(tender)"
                :tender="tender"
                :supplier-id="supplierId"
                :supplier-users="supplierUsers"
              />
            </template>
          </supplier-tender-list-item>
        </base-list>
        <div v-else class="agreements-list__no-tenders">
          {{ noOffersText }}
        </div>
      </template>
      <div v-else class="agreements-list__loader">
        <b-spinner class="table-spinner align-middle" type="grow" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TendersListDetailContent from "./TendersListDetailContent.vue";
import {
  SupplierTenderListItem,
  BaseList,
} from "@really/really-tender-components";
import { watch } from "vue";
import { parseCssStringToPx } from "@/utilities/stringUtils.ts";
import scssVariables from "@/assets/scss/_variables_alias.module.scss";

export default {
  components: {
    TendersListDetailContent,
    SupplierTenderListItem,
    BaseList,
  },
  props: {
    tendersList: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    supplierUsers: {
      type: [Array, null],
      default: null,
    },
  },

  data() {
    return {
      showTenderDetails: [],
      noOffersText: "Ingen tilbud funnet",
    };
  },

  computed: {
    ...mapState(["cms", "api"]),
    serviceTypesCms() {
      return this.$store.state.cms.serviceTypes;
    },
  },
  created() {
    const priceRequestId = parseInt(this.$route.query.priceRequestId);
    if (priceRequestId) {
      this.showTenderDetails.push(priceRequestId);
    }
    this.initializeRowScroll();
  },
  methods: {
    initializeRowScroll() {
      const stopWatchHandle = watch(
        () => this.loading,
        (newVal) => {
          if (newVal) {
            return;
          }
          setTimeout(() => {
            if (stopWatchHandle) {
              stopWatchHandle();
            }
            this.setupSelectedTenderWatcher();
          }, 0);
        },
        { immediate: true },
      );
    },
    setupSelectedTenderWatcher() {
      watch(
        () => this.$route,
        (newRoute) => {
          const priceRequestId = parseInt(newRoute.query.priceRequestId);
          if (!priceRequestId) {
            return;
          }
          if (!this.showTenderDetails.includes(priceRequestId)) {
            this.showTenderDetails.push(priceRequestId);
          }
          this.scrollToRow(priceRequestId);
        },
        { immediate: true },
      );
    },
    scrollToRow(id) {
      const element = document.querySelector(`#row-${id}`);
      if (element) {
        const appHeaderHeight =
          parseCssStringToPx(scssVariables.headerTopBarHeight) +
          parseCssStringToPx(scssVariables.headerBottomBarHeight);
        const offsetFromHeader = 20;
        window.scrollTo({
          behavior: "smooth",
          top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            (appHeaderHeight + offsetFromHeader),
        });
      }
    },
    toggleShowTenderDetails(tender) {
      const priceRequest = tender.priceRequests[0];
      const matchingIndex = this.showTenderDetails.findIndex(
        (priceRequestId) => priceRequestId === priceRequest.id,
      );
      const query = {};
      if (matchingIndex >= 0) {
        this.showTenderDetails.splice(matchingIndex, 1);
      } else {
        this.showTenderDetails.push(priceRequest.id);
        query.priceRequestId = priceRequest.id;
      }
      this.$router.replace({
        query: query,
      });
    },
    isTenderCollapsed(tender) {
      return !this.showTenderDetails.includes(tender.priceRequests[0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
.agreements-list {
  &__section {
    margin-top: 3rem;
  }

  &__title {
    color: $color-primary-dark;
    text-transform: uppercase;
    text-align: left;
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 18px;
  }

  &__no-tenders {
    text-align: center;
    color: $color-primary-darker;
    font-weight: 700;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
  }

  &__loader {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
    color: $color-primary;
  }
}
</style>
