export const MessageDomain = Object.freeze({
  CustomerSupplier: "CustomerSupplier",
  SupplierCustomer: "SupplierCustomer",
});

export const TenderState = Object.freeze({
  Offered: "Offered",
  Accepted: "Accepted",
  Rejected: "Rejected",
  PriceRequested: "PriceRequested",
  InformationRequested: "InformationRequested",
  Completed: "Completed",
});

export const RequestState = Object.freeze({
  Offered: "Offered",
  Accepted: "Accepted",
  Rejected: "Rejected",
  PriceRequested: "PriceRequested",
  InformationRequested: "InformationRequested",
  Completed: "Completed",
});

export const MessageType = Object.freeze({
  PriceOffer: "PriceOffer",
  PriceRequest: "PriceRequest",
  GeneralMessage: "GeneralMessage",
  Compliment: "Compliment",
  Complaint: "Complaint",
});

export const ActivityFrequency = Object.freeze({
  Fixed: "Fixed",
  Monthly: "Monthly",
  Yearly: "Yearly",
});

export const BillingCycle = Object.freeze({
  Fixed: "Fixed",
  Monthly: "Monthly",
  Yearly: "Yearly",
});

export const BillingType = Object.freeze({
  Fixed: "Fixed",
  Variable: "Variable",
});

export const OnceOrMorePerMonthFrequency = Object.freeze({
  Item: "Item",
  SquareMeters: "SquareMeters",
  HoursPerMonth: "HoursPerMonth",
  Sections: "Sections",
});

export const LessThanOncePerMonthFrequency = Object.freeze({
  Item: "Item",
  SquareMeters: "SquareMeters",
  Hours: "Hours",
  Sections: "Sections",
});

export const SingleCostFrequency = Object.freeze({
  Item: "Item",
  SquareMeters: "SquareMeters",
  Hours: "Hours",
  Sections: "Sections",
});

export const ActivityCostType = Object.freeze({
  Fixed: "Fixed",
  Variable: "Variable",
});

export const JobType = Object.freeze({
  Single: "Enkeltjobb",
  Repeating: "Repeterende",
});

export const FeedbackType = Object.freeze({
  Error: "Error",
  Success: "Success",
});

export const RequestType = Object.freeze({
  ServiceWithoutRobot: "ServiceWithoutRobot",
  Robot: "Robot",
});

export const BillingCycleUnits = Object.freeze({
  Yearly: "kr/år",
  Monthly: "kr/mnd",
  Fixed: "kr",
});

export const Months = Object.freeze({
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
});

export const OfferStatus = Object.freeze({
  Draft: "Draft",
  Offered: "Offered",
  Withdrawn: "Withdrawn",
  Accepted: "Accepted",
  Rejected: "Rejected",
  Completed: "Completed",
});

export const Audience = Object.freeze({
  Supplier: "Supplier",
  Customer: "Customer",
});

export const ServiceActivityCmsLabelType = Object.freeze({
  Fixed: "fixed",
  Monthly: "monthly",
  Yearly: "yearly",
});

export const PriceType = Object.freeze({
  Aggregated: "Aggregated",
  Driving: "Driving",
  Independent: "Independent",
});
