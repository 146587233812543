import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum TenderApiEndpoints {
  FETCH_SUPPLIER_OFFERS = "fetchSupplierOffers",
  FETCH_TENDER_OFFERS_BY_SUPPLIER = "fetchTenderOffersBySupplier",
  COMPLETE_TENDER_OFFER_BY_SUPPLIER = "completeTenderOfferBySupplier",
  SEND_INFORMATION_REQUEST_MESSAGE = "sendInformationRequestMessage",
  GET_TENDER_CONFIRMATION_URI = "getTenderConfirmationUri",
  SEND_REJECT_PRICE_REQUEST = "sendRejectPriceRequest",
  GET_TENDER_OFFER = "getTenderOffer",
  GET_TENDER = "getTender",
  SEND_OFFER = "sendOffer",
  DELETE_SUPPLIER_OFFER = "deleteSupplierOffer",
  WITHDRAW_SUPPLIER_OFFER = "withdrawSupplierOffer",
  SET_AGREEMENT_RESPONSIBLE = "setAgreementResponsible",
  GET_INVOICE_RECIPIENT = "getInvoiceRecipient",
  SUBMIT_TENDER = "submitTender",
  GET_PUBLIC_NOTES = "GetPublicNotes",
}

export enum SystemApiEndpoints {
  CREATE_SUPPLIER_TENDER_OFFER = "createSupplierTenderOffer",
  UPDATE_SUPPLIER_TENDER_OFFER = "updateSupplierTenderOffer",
  GET_ACTIVITY_FORM_FREQUENCY_OPTIONS = "getActivityFormFrequencyOptions",
}

export enum CustomerServiceEndpoints {
  GET_FRAME_AGREEMENT = "GetFrameAgreement",
}

export const TenderApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/Tender",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(TenderApiEndpoints.FETCH_SUPPLIER_OFFERS),
      method: "GET",
      url: "",
      description: "Fetch supplier offers",
    },
    {
      key: Symbol.for(TenderApiEndpoints.FETCH_TENDER_OFFERS_BY_SUPPLIER),
      method: "GET",
      url: "/Supplier",
      description: "Fetch tender offers by supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.COMPLETE_TENDER_OFFER_BY_SUPPLIER),
      method: "PUT",
      url: "",
      description: "Complete tender offer by supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SEND_INFORMATION_REQUEST_MESSAGE),
      method: "POST",
      url: "",
      description: "Send information request message",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER_CONFIRMATION_URI),
      method: "GET",
      url: "",
      description: "Get tender confirmation URI",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SEND_REJECT_PRICE_REQUEST),
      method: "POST",
      url: "",
      description: "Send reject price request",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER_OFFER),
      method: "GET",
      url: "/offer/:offerId",
      description: "Get tender offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER),
      method: "GET",
      url: "",
      description: "Get tender",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SEND_OFFER),
      method: "PATCH",
      url: "/offer/:offerId/change",
      description: "Send offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.DELETE_SUPPLIER_OFFER),
      method: "DELETE",
      url: "/Offer/:offerId",
      description: "Delete supplier offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.WITHDRAW_SUPPLIER_OFFER),
      method: "PATCH",
      url: "/offer/:offerId/change",
      description: "Withdraw supplier offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SET_AGREEMENT_RESPONSIBLE),
      method: "PATCH",
      url: "/request/:priceRequestId/supplier",
      description: "Set agreement responsible",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_INVOICE_RECIPIENT),
      method: "GET",
      url: "/InvoiceRecipient/ExternalTenderId/:externalTenderId",
      description: "Get invoice recipient",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SUBMIT_TENDER),
      method: "POST",
      url: "/Create",
      description: "Submit tender",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_PUBLIC_NOTES),
      method: "GET",
      url: "",
      description: "Get public notes",
    },
  ],
};

export const SystemApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/system",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
      method: "POST",
      url: "/offer",
      description: "Create supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
      method: "PUT",
      url: "/offer",
      description: "Update supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS),
      method: "GET",
      url: "/segmentOptions",
      description: "Get activity form frequency options",
    },
  ],
};

export const CustomerServiceApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/Organization",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(CustomerServiceEndpoints.GET_FRAME_AGREEMENT),
      method: "GET",
      url: "",
      description: "Get frame agreement",
    },
  ],
};
