export enum LanguageCode {
  NO = "no",
  EN = "en",
}

export enum MessageDomain {
  CUSTOMER_SUPPLIER = "CustomerSupplier",
  SUPPLIER_CUSTOMER = "SupplierCustomer",
}

export enum TenderState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
}

export enum RequestState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
}

export enum MessageType {
  PRICE_REQUEST = "PriceRequest",
  GENERAL_MESSAGE = "GeneralMessage",
  COMPLIMENT = "Compliment",
  COMPLAINT = "Complaint",
  PRICE_OFFER = "PriceOffer",
}

export enum JobType {
  SINGLE = "Enkeltjobb",
  REPEATING = "Repeterende",
}

export enum ActivityFrequency {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingCycle {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingType {
  FIXED = "Fixed",
  VARIABLE = "Variable",
}

export enum SupplierCreationSource {
  REGISTRATIONS = "Registrations",
  SUPPLIER = "Supplier",
}

export type LocalAttachment = {
  fileName: string;
  attachmentUri: string;
  downloadHandler: () => Promise<void>;
};

export const ReallyAdmin = {
  REALLY_ADMIN: "ReallyAdmin",
} as const;

export const OnceOrMorePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS_PER_MONTH: "HoursPerMonth",
  SECTIONS: "Sections",
} as const;

export const LessThanOncePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export const SingleCostFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export enum RequestType {
  SERVICE_WITHOUT_ROBOT = "ServiceWithoutRobot",
  ROBOT = "Robot",
}

export const BillingCycleUnits = {
  YEARLY: "kr/år",
  MONTHLY: "kr/mnd",
  FIXED: "kr",
} as const;

export const Months = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
} as const;

export enum OfferStatus {
  DRAFT = "Draft",
  OFFERED = "Offered",
  WITHDRAWN = "Withdrawn",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
}

export enum Audience {
  SUPPLIER = "Supplier",
  CUSTOMER = "Customer",
}

export enum ServiceActivityCmsLabelType {
  FIXED = "fixed",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum PriceType {
  AGGREGATED = "Aggregated",
  DRIVING = "Driving",
  INDEPENDENT = "Independent",
}

export interface UserDTO {
  companyName: string | null;
  displayName: string;
  email: string;
  mobilePhone: string | null;
  orgNumber: string;
  principalName: string;
  supplierId: number;
  userId: string;
}

export interface CreateUserDTO {
  name: string | null;
  displayName: string | null;
  email: string | null;
  phoneNumber: string;
  orgNumber: string;
  companyName: string | null;
  password: string;
  supplierId: number | null;
}

export interface CreatedSupplierDTO {
  margin: number;
  commission: number;
  email: string;
  logo: string;
  description: string;
  contactPerson: string;
  orgNumber: string;
  startBank: string;
  revenue: number;
  employees: number;
  reservationLower: number;
  reservationHigher: number;
  reservationKilometers: number;
  certifications: string[];
  sustainabilityGoals: string[];
  offices: string[];
  id: number;
  sysName: string;
  entityPath: string;
}

export interface User {
  userId: string;
  orgNumbers: string;
  phoneNumber: string;
  email: string;
  name: string;
}

export interface EditUserDTO {
  userId: string;
  orgNumber: string;
  phoneNumber: string;
  name: string;
}

export interface SearchSuggestion {
  id: string | number;
  suggestionText: string;
  icon: string;
  disabled?: boolean;
}

export const TenderViewTabLabels = {
  priceRequest: "price-request",
  messages: "messages",
  offers: "offers",
  agreement: "agreement",
  documentation: "documentation",
};
