<template>
  <Teleport to="body">
    <Transition name="side-panel">
      <div v-if="sidePanelStore.isOpen" class="base-side-panel">
        <div class="base-side-panel__content">
          <div class="base-side-panel__header">
            <div class="base-side-panel__title">
              {{ sidePanelStore.props.title }}
            </div>
            <button
              class="base-side-panel__close-button"
              @click="sidePanelStore.closePanel"
            >
              <img :src="CloseIcon" alt="Close" />
            </button>
          </div>
          <div class="base-side-panel__body">
            <component
              :is="sidePanelStore.component"
              v-bind="sidePanelStore.props"
              @close="sidePanelStore.closePanel"
            />
          </div>
          <div class="base-side-panel__footer">
            <BaseButton
              text="Lukk"
              uppercase
              shape="rounded"
              variant="tertiary"
              size="md"
              @click="sidePanelStore.closePanel"
            />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { useSidePanelStore } from "@/stores/sidePanel/sidePanel";
import CloseIcon from "@/assets/img/Close.svg";

const sidePanelStore = useSidePanelStore();
</script>

<style lang="scss" scoped>
.base-side-panel {
  --z-index: 11000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: calc(var(--z-index) - 1);
  background: rgba(0, 0, 0, 0.05);

  &__content {
    margin-left: auto;
    height: 100%;
    width: 100%;
    max-width: 500px;
    z-index: var(--z-index);
    display: flex;
    flex-direction: column;
    background-color: $color-white;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
  }

  &__body {
    padding: 0 1.5rem 1.5rem;
    flex-grow: 1;
    overflow: auto;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    background: $color-grey-light;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__close-button {
    border: none;
    background: transparent;
    padding: 0.15rem;
  }
}
</style>
