import { Tender, PriceRequest } from "@/stores/tender/types";
import {
  RequestType,
  RequestState,
  OfferStatus,
  TenderState,
  BillingCycle,
} from "@/custom-types/GeneralTypes";
import { cloneDeep } from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTendersFromStore = (state: any): Tender[] => state.offers || [];

export const isManualTender = (tender: Tender): boolean =>
  tender.priceRequests[0]?.requestType === RequestType.SERVICE_WITHOUT_ROBOT;

export const getServiceOfferFromTender = (
  serviceOfferId: number,
  tender: Tender,
) => {
  if (!tender?.supplierPortfolios) {
    return null;
  }

  const { supplierPortfolios } = tender;

  if (!Array.isArray(supplierPortfolios)) {
    return null;
  }

  return supplierPortfolios
    .flatMap(
      ({ serviceTypePackagePortfolio }) => serviceTypePackagePortfolio || [],
    )
    .flatMap(({ serviceOffers }) => serviceOffers || [])
    .find(({ id }) => id === serviceOfferId);
};

export const isTenderSingleJob = (tender: Tender): boolean => {
  if (
    !tender ||
    !tender.supplierPortfolios ||
    !tender.supplierPortfolios.length
  ) {
    return false;
  }

  const acceptedServiceOffer = tender.supplierPortfolios
    .flatMap((portfolio) => portfolio.serviceTypePackagePortfolio || [])
    .flatMap((packagePortfolio) => packagePortfolio.serviceOffers || [])
    .find((serviceOffer) => serviceOffer.offerState === OfferStatus.ACCEPTED);

  return acceptedServiceOffer?.billingCycle === BillingCycle.FIXED;
};

interface AddressTender {
  tenders: Tender[];
  address?: string;
}

export const mapToAddressTenders = (tenders: Tender[]): AddressTender[] =>
  tenders
    .sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    )
    .map((tender) => ({
      tenders: [tender],
      address: tender.priceRequests?.[0]?.customerPropertyDto?.address?.address,
    }));

export const filterTendersByState = (
  tenders: Tender[],
  states: TenderState[],
): Tender[] =>
  tenders.filter((tender) =>
    states.includes(tender.tenderState as TenderState),
  );

export const filterNotRejectedTenders = (tenders: Tender[]): Tender[] =>
  tenders.filter(
    (tender) =>
      tender.priceRequests?.[0]?.supplierRequests?.[0]?.requestState !==
      RequestState.REJECTED,
  );

export const filterManualTenders = (tenders: Tender[]): Tender[] =>
  tenders.filter(isManualTender);

export const createActiveTenders = (tenders: Tender[]): Tender[] => {
  const activeTenders: Tender[] = [];
  const portfolioPriceRequests: {
    tender: Tender;
    priceRequest: PriceRequest;
  }[] = [];

  tenders.forEach((tender) => {
    if (tender.priceRequests.length > 1) {
      tender.priceRequests.forEach((priceRequest) => {
        const pR = {
          tender: tender,
          priceRequest: priceRequest,
        };
        portfolioPriceRequests.push(pR);
      });
    } else {
      activeTenders.push(tender);
    }
  });

  portfolioPriceRequests.forEach((pr) => {
    const tender = { ...pr.tender };
    const customerPropertyId = pr.priceRequest.customerPropertyId;
    tender.priceRequests = [pr.priceRequest];

    tender.supplierPortfolios = tender.supplierPortfolios.map((supplier) => ({
      ...supplier,
      serviceTypePackagePortfolio: supplier.serviceTypePackagePortfolio.map(
        (pkg) => ({
          ...pkg,
          serviceOffers: pkg.serviceOffers.filter(
            (offer) => offer.customerPropertyId === customerPropertyId,
          ),
        }),
      ),
    }));

    activeTenders.push(tender);
  });

  return activeTenders;
};

export const getTenders = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  tenderStates: TenderState[],
): AddressTender[] => {
  const tenders = cloneDeep(getTendersFromStore(state));
  const filteredTenders = filterTendersByState(tenders, tenderStates);
  const notRejectedTenders = filterNotRejectedTenders(filteredTenders);
  const manualTenders = filterManualTenders(notRejectedTenders);

  return mapToAddressTenders(manualTenders);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRejectedTenderOffers = (state: any): AddressTender[] => {
  const tenders = cloneDeep(getTendersFromStore(state));

  const requestedStates = [
    TenderState.PRICE_REQUESTED,
    TenderState.INFORMATION_REQUESTED,
  ];

  const filteredTenders = filterTendersByState(tenders, requestedStates);

  const rejectedTenders = filteredTenders.filter((tender) =>
    tender.priceRequests?.[0]?.supplierRequests?.some(
      ({ requestState }) => requestState === RequestState.REJECTED,
    ),
  );

  const manualTenders = filterManualTenders(rejectedTenders);

  return mapToAddressTenders(manualTenders);
};

export const getRejectedTenders = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  tenderStates: TenderState[],
): AddressTender[] => {
  const tenders = cloneDeep(getTendersFromStore(state));
  const filteredTenders = filterTendersByState(tenders, tenderStates);
  const rejectedTenders = filteredTenders.filter(
    (tender) => tender.tenderState === TenderState.REJECTED,
  );
  return mapToAddressTenders(rejectedTenders);
};

export const getPriceRequestMessage = (serviceTender: Tender) => {
  const priceRequest = serviceTender?.priceRequests?.[0];
  return priceRequest?.message ?? null;
};

export const getSurveyDateTime = (serviceTender: Tender) => {
  const priceRequest = serviceTender?.priceRequests?.[0];
  return priceRequest?.surveyDateTime ?? null;
};

export const getPriceRequestSentDate = (serviceTender: Tender) => {
  const createdOn = serviceTender?.createdOn;
  return createdOn ? createdOn : null;
};

export const getCustomerWantedStartDate = (serviceTender: Tender) => {
  if (isRequestTypeRobot(serviceTender)) {
    const supplierPortfolios = serviceTender.supplierPortfolios ?? [];
    const serviceTypePackagePortfolio = supplierPortfolios
      .flatMap((portfolio) => portfolio.serviceTypePackagePortfolio)
      .find(
        (portfolio) =>
          portfolio.serviceTypePortfolioAcceptance &&
          portfolio.serviceTypePortfolioAcceptance.acceptedOn &&
          portfolio.serviceTypePortfolioAcceptance.startDate,
      );
    const startDate =
      serviceTypePackagePortfolio?.serviceTypePortfolioAcceptance?.startDate;
    return startDate ? startDate : "";
  } else {
    return serviceTender.priceRequests[0]?.wantedStartDate ?? "";
  }
};

const isRequestTypeRobot = (serviceTender: Tender) => {
  return serviceTender.priceRequests.some(
    (request) => request.requestType === RequestType.ROBOT,
  );
};
