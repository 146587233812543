<template>
  <div class="attachment-wrapper">
    <div class="attachment-wrapper__title">Valgt fil:</div>
    <div
      v-for="(attachment, index) in attachments"
      :key="index"
      class="attachment"
    >
      <span class="attachment__text">
        {{ attachment.fileName }}
      </span>
      <b-button
        size="lg"
        variant="link"
        class="mb-2"
        @click="handleDeleteAttachmentClick(attachment)"
      >
        <img :src="`/images/service-report/delete.svg`" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },
  emits: ["remove-attachment"],
  methods: {
    handleDeleteAttachmentClick(attachment) {
      this.$emit("remove-attachment", attachment);
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  &__title {
    font-size: $font-size-sm;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #5b0050;
  }

  .attachment {
    display: flex;
    width: fit-content;
    margin-top: -0.625rem;
    align-items: baseline;

    &__text {
      font-weight: 400;
      font-size: $font-size-sm;
      color: #5b0050;
    }

    &__delete-btn {
      cursor: pointer;
      width: 1.75rem;
      height: 1.75rem;
      margin-left: 0.313rem;
    }

    &__with-link {
      cursor: pointer;
    }

    &__without-link {
      cursor: no-drop;
    }
  }
}
</style>
