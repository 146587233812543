<template>
  <div class="supplier-offer-list">
    <div v-for="state in states" :key="state" class="state-section">
      <supplier-offer-item
        v-for="(offer, i) in getFilteredOffersByState(state)"
        :key="offer.id"
        :offer="offer"
        :tender="tender"
        :frequency-options="frequencyOptions"
        :is-first="i === 0"
        :is-last="
          i === getFilteredOffersByState(state).length - 1 &&
          getFilteredOffersByState(state).length >= 1
        "
        @open-send-to-customer-modal="handleOpenSendToCustomerOfferModal"
        @open-delete-draft-modal="handleOpenDeleteDraftOfferModal(offer)"
        @open-copy-modal="handleCopyOffer(offer)"
        @open-edit-sent-or-withdrawn-offer-modal="
          handleOpenEditSentOrWithdrawnOfferModal
        "
        @open-edit-modal="handleOpenEditItemModal"
        @open-revoke-offer-modal="handleOpenWithdrawOffer"
        @open-see-agreement-modal="handleSeeAgreementClick"
        @open-download-terms-modal="handleDownloadTermsClick"
      />
    </div>
    <send-offer-modal
      v-if="offerToSendToCustomer"
      v-model="showSendOfferModal"
      :tender="tender"
      :service-offer="offerToSendToCustomer"
      :supplier-id="supplierId"
      @offer-sent="handleSendToCustomerOffer"
    />
    <success-offer-modal
      v-model="showSuccessOfferModal"
      :success-offer-modal-cms="cmsProps.successOfferModalCms"
      :reload-my-agreements="reloadMyAgreements"
      @success-event="handleSuccessEvent"
      @close="handleCloseModal"
    />
    <delete-draft-offer-modal
      v-if="offerToDelete"
      v-model="showDeleteDraftOfferModal"
      :delete-draft-offer-modal-cms="cmsProps.deleteDraftOfferModalCms"
      :message-deleted-text="messageResponseText"
      @close="handleCloseModal"
      @delete-draft-offer="handleDeleteOffer"
    />
    <edit-sent-or-withdrawn-offer-modal
      v-model="showEditSentOrWithdrawOfferModal"
      :offer="offerToEdit"
      :edit-sent-or-withdrawn-offer-modal-cms="
        cmsProps.editSentOrWithdrawnOfferModalCms
      "
      @close="handleCloseModal"
      @edit="handleEditSentOrWithdrawOffer"
    />
    <withdraw-offer-modal
      v-if="offerToWithdraw"
      v-model="showWithdrawOfferModal"
      :withdraw-offer-modal-cms="cmsProps.withdrawOfferModalCms"
      :message-withdrawn-text="messageResponseText"
      @close="handleCloseModal"
      @withdraw-offer="handleWithdrawOffer"
    />
    <div v-if="error" class="supplier-offer-list__error-message">
      <feedback-overlay
        :feedback-type="error.type"
        :message-title="error.messageTitle"
        :message-info="error.messageInfo"
        @close="clearError"
      />
    </div>
  </div>
</template>

<script>
import SupplierOfferItem from "./SupplierOfferItem.vue";
import SuccessOfferModal from "../SupplierOffersModals/SuccessOfferModal.vue";
import DeleteDraftOfferModal from "../SupplierOffersModals/DeleteDraftOfferModal.vue";
import EditSentOrWithdrawnOfferModal from "../SupplierOffersModals/EditSentOrWithdrawnOfferModal.vue";
import WithdrawOfferModal from "../SupplierOffersModals/WithdrawOfferModal.vue";
import SendOfferModal from "@/components/SendOfferModal/SendOfferModal.vue";
import { getServiceOfferFromTender } from "@/utilities/tenderUtilities";
import { OfferStatus, FeedbackType, Audience } from "@/custom-types/types";
import tenderService from "@/services/tenderService";
import cmsService from "@/services/cmsService";
import FeedbackOverlay from "@/components/FeedbackOverlay/FeedbackOverlay.vue";
import { mapGetters } from "vuex";
import { RouteNames } from "@/router/routes";
import { getFileUrl } from "@/utilities/cmsUtilities";
import useFileDownload from "@/hooks/useFileDownload.ts";

const SUCCESS_STATUS = 200;
const WITHDRAWN_ERROR_TITLE = "Feil ved tilbaketrekking av tilbud";
const DELETE_ERROR_TITLE = "Feil ved sletting av tilbud";
const MESSAGE_DELETED = "Tilbudet er slettet";
const MESSAGE_WITHDRAWN = "Tilbudet er trukket tilbake";

const { viewBlobFile } = useFileDownload();

export default {
  name: "SupplierOfferList",
  components: {
    SupplierOfferItem,
    SendOfferModal,
    SuccessOfferModal,
    DeleteDraftOfferModal,
    EditSentOrWithdrawnOfferModal,
    WithdrawOfferModal,
    FeedbackOverlay,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierOffers: {
      type: Array,
      default: () => [],
    },
    cmsProps: {
      type: Object,
      default: () => {},
    },
    supplierId: {
      type: Number,
      required: true,
    },
    offerStateHasChanged: {
      type: Boolean,
      default: false,
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["reload-my-agreements", "refresh-tender-offer-data"],
  data() {
    return {
      states: [OfferStatus.Offered, OfferStatus.Draft, "WithdrawnOrRejected"],
      showWithdrawOfferModal: false,
      showSendToCustomerOfferModal: false,
      showSuccessOfferModal: false,
      showDeleteDraftOfferModal: false,
      showEditSentOrWithdrawOfferModal: false,
      offerToSendToCustomer: null,
      offerToDelete: null,
      offerToEdit: null,
      offerToCopy: null,
      offerToWithdraw: null,
      showSendOfferModal: false,
      error: null,
      messageResponseText: "",
      reloadMyAgreements: false,
      offers: [],
      termsAndConditionsCms: null,
    };
  },
  computed: {
    serviceOffer() {
      const offerId = this.$route.params.offerId;
      if (!offerId) {
        return null;
      }
      return getServiceOfferFromTender(parseInt(offerId), this.tender);
    },
    ...mapGetters("api", ["getUserId"]),
  },
  watch: {
    supplierOffers: {
      handler(newOffers) {
        this.offers = newOffers;
        if (this.offerStateHasChanged) this.handleChangeOfferState(newOffers);
      },
      immediate: true,
    },
  },
  async created() {
    await this.fetchTermsAndConditionsCms();
  },
  methods: {
    getFilteredOffersByState(state) {
      let filteredOffers;

      if (state === "WithdrawnOrRejected") {
        filteredOffers = this.offers.filter(
          (offer) =>
            offer.offerState === OfferStatus.Withdrawn ||
            offer.offerState === OfferStatus.Rejected,
        );
      } else {
        filteredOffers = this.offers.filter(
          (offer) => offer.offerState === state,
        );
      }

      return filteredOffers.sort((a, b) => {
        const dateTimeA = this.offerStateDateTime(a) || "";
        const dateTimeB = this.offerStateDateTime(b) || "";
        return new Date(dateTimeB) - new Date(dateTimeA);
      });
    },

    offerStateDateTime(offer) {
      const offerStateChange = this.findOfferStateChange(offer);
      return offerStateChange?.createdOn;
    },

    findOfferStateChange(offer) {
      return offer.offerStateChanges.find(
        (offerStateChange) => offerStateChange.stateTo === offer.offerState,
      );
    },

    handleChangeOfferState(newOffers) {
      const numberOfOffersInOfferedState =
        this.countOffersInOfferedState(newOffers);
      if (
        numberOfOffersInOfferedState === 1 ||
        numberOfOffersInOfferedState === 0
      ) {
        this.$emit("reload-my-agreements", newOffers);
      }
    },

    handleOpenSendToCustomerOfferModal(offer) {
      this.offerToSendToCustomer = offer;
      this.showSendOfferModal = true;
    },

    handleCloseModal() {
      this.showSendToCustomerOfferModal = false;
      this.showDeleteDraftOfferModal = false;
      this.showEditSentOrWithdrawOfferModal = false;
      this.showSuccessOfferModal = false;
    },

    async handleServiceRequest(requestFunc, offer, postSuccessAction) {
      try {
        const response = await requestFunc(this.tender.tenderId, offer.id);
        this.handleResponse(response, postSuccessAction);
      } catch (error) {
        const errorTitle =
          offer.offerState === OfferStatus.Withdrawn
            ? WITHDRAWN_ERROR_TITLE
            : DELETE_ERROR_TITLE;
        this.handleError(error, errorTitle);
      } finally {
        //this.showSuccessOfferModal = true;
      }
    },

    async handleDeleteOffer() {
      this.handleServiceRequest(
        tenderService.deleteSupplierOffer,
        this.offerToDelete,
        () => {
          this.removeOfferById(this.offerToDelete.id);
          this.messageResponseText = MESSAGE_DELETED;
        },
      );
    },

    async handleWithdrawOffer() {
      await this.handleServiceRequest(
        this.performWithdrawSupplierOffer,
        this.offerToWithdraw,
        this.handleWithdrawnOffer,
      );
    },

    constructChangeOfferDto() {
      return {
        SupplierId: this.supplierId,
        Audience: Audience.Supplier,
        State: OfferStatus.Withdrawn,
        UserId: this.getUserId,
      };
    },

    performWithdrawSupplierOffer(tenderId, offerId) {
      return tenderService.withdrawSupplierOffer(
        tenderId,
        offerId,
        this.constructChangeOfferDto(),
      );
    },

    handleWithdrawnOffer() {
      this.changeOfferStateToWithdrawn(this.offerToWithdraw.id);
    },

    changeOfferStateToWithdrawn(offerId) {
      const index = this.offers.findIndex((offer) => offer.id === offerId);

      if (index !== -1) {
        const updatedOffers = [...this.offers];
        updatedOffers[index].offerState = OfferStatus.Withdrawn;
        this.offers = updatedOffers;
      }

      this.messageResponseText = MESSAGE_WITHDRAWN;

      if (this.hasNoOfferInOfferedState()) {
        this.messageResponseText = MESSAGE_WITHDRAWN;
        this.showWithdrawOfferModal = false;
        this.refreshTenderOfferData();
      }

      this.refreshTenderOfferData(false);
    },

    hasNoOfferInOfferedState() {
      return this.offers.every(
        (offer) => offer.offerState !== OfferStatus.Offered,
      );
    },

    countOffersInOfferedState(offers) {
      return offers.filter((offer) => offer.offerState === OfferStatus.Offered)
        .length;
    },

    handleResponse(response, postSuccessAction) {
      if (response.status === SUCCESS_STATUS) {
        postSuccessAction();
      }
    },

    handleError(error, errorTitle) {
      this.messageResponseText = MESSAGE_DELETED;
      this.messageResponseText = MESSAGE_WITHDRAWN;
      const errorMessage = error?.response?.data;
      this.error = {
        type: FeedbackType.Error,
        messageTitle: errorTitle, // You might need to pass this as a parameter if the title varies
        messageInfo: errorMessage ? JSON.stringify(errorMessage) : "",
      };
      setTimeout(this.clearError, 4500);
    },

    refreshTenderOfferData(offerStateChange = true) {
      this.$emit("refresh-tender-offer-data", offerStateChange);
    },

    removeOfferById(id) {
      const index = this.offers.findIndex((offer) => offer.id === id);
      if (index !== -1) {
        this.offers.splice(index, 1);
      }
      this.reloadMyAgreements = false;
    },

    clearError() {
      this.error = null;
    },

    handleCopyOffer(offer) {
      this.redirectToEditCopyOffer(offer);
    },

    handleOpenDeleteDraftOfferModal(offer) {
      this.offerToDelete = offer;
      this.showDeleteDraftOfferModal = true;
    },

    handleOpenEditSentOrWithdrawnOfferModal(offer) {
      this.offerToEdit = offer;
      this.showEditSentOrWithdrawOfferModal = true;
    },

    handleEditSentOrWithdrawOffer(offer) {
      this.redirectToEditCopyOffer(offer);
      this.showEditSentOrWithdrawOfferModal = false;
    },

    redirectToEditCopyOffer(offer) {
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        params: { tenderId: this.tender.tenderId },
        query: {
          "reusable-offer": offer.id,
          duplicate: true,
        },
      });
    },

    handleOpenWithdrawOffer(offer) {
      this.offerToWithdraw = offer;
      this.showWithdrawOfferModal = true;
    },

    handleOpenEditItemModal(offer) {
      if (offer.offerState === OfferStatus.Draft) {
        this.redirectToEditOffer(offer.id);
      } else {
        this.offerToEdit = offer;
        this.showEditSentOrWithdrawOfferModal = true;
      }
    },

    redirectToEditOffer(offerId) {
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        params: {
          tenderId: this.tender.tenderId,
          offerId: offerId,
        },
      });
    },

    handleSuccessEvent(refresh = false) {
      if (refresh) {
        this.refreshTenderOfferData();
      }
      this.handleCloseModal();
    },

    handleSendToCustomerOffer() {
      this.reloadMyAgreements = true;
      this.showSendOfferModal = false;
      this.showSuccessOfferModal = true;
    },

    async handleSeeAgreementClick(offer) {
      const serviceOfferId = offer.id;
      if (!serviceOfferId) {
        return;
      }
      await viewBlobFile(async () => {
        const response = await tenderService.getTenderConfirmationUri(
          this.tender.tenderId,
          serviceOfferId,
          "Supplier",
        );
        return response.data;
      }, this.$router);
    },

    async fetchTermsAndConditionsCms() {
      const response = await cmsService.getTermsAndConditionsCms();
      if (response && response.length) {
        this.termsAndConditionsCms = response[0];
      }
    },

    handleDownloadTermsClick() {
      const url = getFileUrl(
        this.termsAndConditionsCms?.filedoc,
        "seeAllTerms",
      );
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary: #611f69;
$color-primary-darker: darken($color-primary, 10%);
$btn-secondary-padding: 16px 40px;
$btn-secondary-font-weight: bold;
$btn-secondary-font-size: $font-size-base;
$btn-border-width: 0.1rem;
$btn-box-shadow: 0 2px 10px 0 rgba($color-primary, 0.25);

.supplier-offer-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.state-section {
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

:deep(.btn-secondary) {
  width: fit-content;
  margin-bottom: 30px;
  font-weight: $btn-secondary-font-weight;
  font-size: $btn-secondary-font-size;
  text-transform: uppercase !important;
  background-color: $color-primary;
  border-color: $color-primary;
  border-width: $btn-border-width;
  color: white;
  padding: $btn-secondary-padding;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary-darker;
    border-color: $color-primary-darker;
    color: white;
  }

  &:focus {
    box-shadow: $btn-box-shadow;
  }
}
</style>
