<template>
  <div class="supplier-offer-item" :class="containerClasses">
    <img
      :src="`/images/document.svg`"
      alt="Document icon"
      class="supplier-offer-item__icon"
    />
    <p class="supplier-offer-item__offer-title">{{ offerName }}</p>
    <div class="supplier-offer-item__amount-section">
      <p class="supplier-offer-item__price">
        {{ supplierCost }}
      </p>
      <p class="supplier-offer-item__total-text">
        {{ totalAnnualPriceText }}
      </p>
    </div>
    <hr class="supplier-offer-item__vertical-line" />
    <div class="supplier-offer-item__status-section" :class="offer.offerState">
      <p class="supplier-offer-item__offer-status">
        {{ offerStateLabel }}
      </p>
      <p class="supplier-offer-item__last-changed">{{ displayDate }}</p>
    </div>
    <div class="supplier-offer-item__menu-section">
      <supplier-offer-menu-dropdown
        :tender="tender"
        :offer="offer"
        @item-action="handleItemAction"
      />
    </div>
  </div>
</template>

<script>
import SupplierOfferMenuDropdown from "../SupplierOfferMenu/SupplierOfferMenuDropdown.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { convertDateToLocaleTimeStringDotSeparated } from "@/utilities/dateUtilities";
import { OfferStatus, BillingCycleUnits } from "@/custom-types/types";
import { RouteNames } from "@/router/routes";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "SupplierOfferItem",
  components: {
    SupplierOfferMenuDropdown,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currency: "kr",
      totalAnnualBillableText: "Total fakturerbar pris",
      totalAnnualBillableCostText: "Gjenomsnittlig",
      lastModifiedAtText: "Sist endret kl.",
      wantedStartDateText: "Oppstart",
      completedDateText: "Avsluttet",
      validThroughText: "Gyldig ut",
      rejectedDateText: "Kl.",
      billingCycleSuffixes: {
        Yearly: "kostnad per år",
        Monthly: "kostnad per måned",
        Fixed: "totalkostnad",
      },
    };
  },
  computed: {
    ...mapGetters("supplier", {
      supplier: "getSupplier",
    }),
    containerClasses() {
      return {
        first: this.isFirst,
        last: this.isLast,
      };
    },
    offerName() {
      const defaultName = "Tilbud på tjenestetype";
      const serviceOfferExtra = this.offer.serviceOfferExtra;
      return serviceOfferExtra?.nickname
        ? serviceOfferExtra?.nickname
        : defaultName;
    },
    totalAnnualPriceText() {
      let baseText;
      let shouldAppendSuffix = false;

      if (
        [OfferStatus.Accepted, OfferStatus.Completed].includes(
          this.offer.offerState,
        )
      ) {
        baseText = this.totalAnnualBillableCostText;
        shouldAppendSuffix = true;
      } else {
        baseText = this.totalAnnualBillableText;
      }

      const billingCycleSuffix =
        this.billingCycleSuffixes[this.offer?.billingCycle];

      return shouldAppendSuffix
        ? `${baseText} ${billingCycleSuffix}`
        : baseText;
    },
    offerStateLabel() {
      const offerStateMapping = {
        Draft: "Utkast",
        Withdrawn: "Trukket tilbake",
        Offered: "Sendt til kunde",
        Accepted: "Signert avtale",
        Rejected: "Avvist av kunde",
        Completed: "Utført avtale",
      };

      return offerStateMapping[this.offer.offerState];
    },
    supplierCost() {
      const billingCycle = BillingCycleUnits[this.offer?.billingCycle];
      return `${formatCurrency(this.offer?.supplierCost)} ${
        billingCycle ?? ""
      }`;
    },
    displayDate() {
      const stateMapping = {
        [OfferStatus.Completed]: this.completedDate,
        [OfferStatus.Accepted]: this.wantedStartDate,
        [OfferStatus.Rejected]: this.rejectedDate,
        [OfferStatus.Withdrawn]: this.withdrawnDate,
        [OfferStatus.Offered]: this.offerDate,
      };

      return stateMapping[this.offer.offerState] || this.lastSavedDate;
    },
    wantedStartDate() {
      return `${this.wantedStartDateText} ${this.formatDate(
        this.getWantedStartDate,
      )}`;
    },
    completedDate() {
      return `${this.completedDateText} ${this.formatDate(
        this.offer?.completion?.completedOn,
      )}`;
    },
    rejectedDate() {
      return `${this.rejectedDateText} ${this.lastModifiedDateTime}`;
    },
    withdrawnDate() {
      return `${this.rejectedDateText} ${this.lastModifiedDateTime}`;
    },
    lastSavedDate() {
      return `${this.lastModifiedAtText} ${this.lastModifiedDateTime}`;
    },
    offerDate() {
      return `${this.validThroughText} ${this.lastModifiedOfferDate}`;
    },
    lastModifiedOfferDate() {
      const offerStateChange = this.findOfferStateChange;
      const validThrough = offerStateChange?.createdOn;

      return dayjs(validThrough).format("DD.MM.YYYY");
    },
    lastModifiedDateTime() {
      const offerStateChange = this.findOfferStateChange;
      const createdOn = offerStateChange?.createdOn;

      return dayjs(createdOn).format("HH:mm:ss - DD.MM.YYYY");
    },
    findOfferStateChange() {
      if (!this.offer) {
        return null;
      }
      return this.offer.offerStateChanges.find(
        (offerStateChange) =>
          offerStateChange.stateTo === this.offer.offerState,
      );
    },
    getWantedStartDate() {
      if (!this.tender) return "";

      const serviceTypePackagePortfolio =
        this.getSelectedServiceTypePackagePortfolio();

      return (
        serviceTypePackagePortfolio?.serviceTypePortfolioAcceptance
          ?.startDate ?? ""
      );
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return convertDateToLocaleTimeStringDotSeparated(date) ?? "";
    },
    handleItemAction(action) {
      if (action === "view-offer")
        return this.redirectToEditOffer(this.offer.id);
      this.$emit(`open-${action}-modal`, this.offer);
    },
    redirectToEditOffer(offerId) {
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        params: {
          tenderId: this.tender.tenderId,
          offerId: offerId,
        },
      });
    },

    getSelectedServiceTypePackagePortfolio() {
      if (!this.tender.supplierPortfolios) return;

      const serviceTypePackagePortfolios =
        this.tender.supplierPortfolios.flatMap(
          (supplierPortfolio) => supplierPortfolio.serviceTypePackagePortfolio,
        );

      return serviceTypePackagePortfolios.find(
        this.isServiceTypePackagePortfolioSelected,
      );
    },

    isServiceTypePackagePortfolioSelected(serviceTypePackagePortfolio) {
      return serviceTypePackagePortfolio.serviceTypePortfolioAcceptance;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary: #fdfbfa;
$border-color: #9a9a9a;
$dark-color: #000000;
$light-grey: #787878;
$grey: #9a9a9a;
$padding-size: 16px;
$border-radius-size: 4px;
$line-height-size: 40px;
$vertical-line-width: 1px;
$font-400: 400;
$font-600: 600;

@mixin font-style($weight, $size, $color) {
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

.supplier-offer-item {
  display: flex;
  align-items: center;
  padding: $padding-size;
  background: $color-primary;
  border: 1px solid $border-color;

  &__icon,
  &__title,
  &__offer-title,
  &__price {
    margin-left: $padding-size;
  }

  &__title,
  &__price,
  &__offer-title,
  &__offer-status {
    @include font-style($font-600, 16px, $dark-color);
    margin-bottom: 0;
  }

  &__offer-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
    text-align: left;
    width: 22rem;
  }

  &__offer-status {
    font-size: $font-size-sm;
  }

  &__amount-section {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;
    margin-top: 0.2rem;
  }

  &__price {
    @include font-style($font-600, 16px, $dark-color);
    margin-bottom: 0;
    margin-top: 0.25rem;
  }

  &__offer-status {
    @include font-style($font-600, 14px, $dark-color);
    margin-bottom: 0;
    margin-top: 0.25rem;
  }

  &__total-text,
  &__last-changed {
    @include font-style($font-400, 14px, $light-grey);
    margin-bottom: 0.5rem;
    min-width: max-content;
  }

  &__last-changed {
    font-size: $font-size-xs;
  }

  &__vertical-line {
    width: $vertical-line-width;
    height: $line-height-size;
    background: $grey;
    margin-left: $padding-size;
    margin-right: $padding-size;
  }

  &__status-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 8px;
    width: 224px;
    height: 48px;
    border-radius: $border-radius-size;

    &.Sent {
      background: #f7e2b5;
    }

    &.Draft {
      background: #f4ede4;
    }

    &.Rejected {
      background: #f4e4e4;
    }

    &.Offered {
      background: #f7e2b5;
    }

    &.Withdrawn {
      background: #f4e4e4;
    }

    &.Accepted {
      background: #8cd0ac;
    }

    &.Completed {
      background: #8cd0ac;
    }

    &.Rejected {
      background: #f4e4e4;
    }
  }

  &__menu-section {
    border-radius: $border-radius-size;
    margin-left: $padding-size;
  }

  &.first {
    border-top-left-radius: $border-radius-size;
    border-top-right-radius: $border-radius-size;
  }
  &.last {
    border-bottom-left-radius: $border-radius-size;
    border-bottom-right-radius: $border-radius-size;
  }
}
</style>
