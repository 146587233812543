import { TenderState } from "@/custom-types/types";
import {
  getTendersFromStore,
  filterTendersByState,
  mapToAddressTenders,
  createActiveTenders,
  getTenders,
  getRejectedTenders,
  getRejectedTenderOffers,
} from "@/utilities/offerUtilities";
import { cloneDeep } from "lodash";

// Getters
export default {
  getAllTenders: (state) => state.offers,

  getRequestedOffers: (state) => {
    return getTenders(state, [
      TenderState.PriceRequested,
      TenderState.InformationRequested,
    ]);
  },

  getOfferedOffers: (state) => getTenders(state, [TenderState.Offered]),

  getActiveOffers: (state) => {
    const tenders = cloneDeep(getTendersFromStore(state));
    const activeTenders = filterTendersByState(tenders, [TenderState.Accepted]);
    const activeAndPreparedTenders = createActiveTenders(activeTenders);
    return mapToAddressTenders(activeAndPreparedTenders);
  },

  getTerminatedOrArchivedOffers: (state) => {
    const completedTenders = getTenders(state, [TenderState.Completed]);
    const rejectedTenders = getRejectedTenders(state, [TenderState.Rejected]);
    const rejectedTenderOffers = getRejectedTenderOffers(state);

    return [...completedTenders, ...rejectedTenders, ...rejectedTenderOffers];
  },
};
